/// CLUSTER
/// A little layout that pushes items away from each other where
/// there is space in the viewport and stacks on small viewports

/// CUSTOM PROPERTIES AND CONFIGURATION
/// --gutter ($global-gutter): This defines the space
/// between each item.

/// --repel-vertical-alignment How items should align vertically.
/// Can be any acceptable flexbox alignment value.
.repel {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: var(--repel-vertical-alignment, center);
    gap: var(--gutter, 1.3rem);
}
