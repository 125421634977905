
html, body{
	min-width: 290px;
	-ms-overflow-style: scrollbar;
}
body{
	background-color: @body-bg;
	font-family: @font-family-base;
	font-size: @font-size-base;
	color:@body-color;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased !important;
}

h1,
.h1 {
	font-size: 34px;
}
h2,
.h2 {
	font-size: 28px;
}
h3,
.h3 {
	font-size: 22px;
}
h4,
.h4 {
	font-size: 16px;
}
h5,
.h5 {
	font-size: 12px;
}
h6,
.h6 {
	font-size: 10px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
	font-weight: @font-semibold;
}

.selection(@selection-color);


/* CONTAINER */
/*======================================*/
#container {
    min-height: 100vh;
    height: auto;
    position: relative;
    min-width: 290px;
    /*overflow: hidden;*/
}

#container.boxed-layout{
	background-color: multiply(@body-bg, #e5e5e5);
}

/* CONTENT */
/*======================================*/
#content-container{
	position: relative;
	padding-top: @navbar-height * 2;
	padding-bottom: 100px;
	background-color: @body-bg
}





/* PAGE HEADER */
/*======================================*/
#page-title {
	padding: 10px 20px;
	width: 100%;
}

.page-header {
	border: 0 none;
	color: lighten(@body-color, 17%);
	font-size: 2.2em;
	font-weight: 100;
	margin: 0;
	padding: 10px 0;
	.label{
		margin-left: .5em
	}
}




/* PAGE CONTENT */
/*======================================*/
#page-content{
	padding: 5px 20px 0;
}
@media (min-width: 768px){
	#page-content{
		padding: 10px 20px 0;
	}
}
@media (min-width: 992px){
	#page-content{
		padding: 20px 25px 0;
	}
}




/* SEARCHBOX */
/*======================================*/
.searchbox {
	margin-right: -20px;
	button {
		border: 0;
		background: none;
		padding: 2px 5px;
		margin-top: 2px;
		position: relative;
		left: -28px;
		font-size: 14px;
		z-index: 2
	}
	.custom-search-form{
		margin-right: -5px;
		input{
			border-color: transparent;
			padding-right: 30px
		}
	}
	> .input-group.custom-search-form input{
		border-radius: @border-radius-base;
	}
	> .input-group input:focus{
		border-color: @state-active-bg;
	}
}





/* PAGE CONTENT - RESPONSIVE LAYOUT */
/*======================================*/
@media (min-width: 768px){
	#content-container{
		padding-top: @navbar-height;
	}
	#page-title {
		display: table;
		table-layout: fixed;
		height: 51px;
		padding:0 0 0 15px
	}
	.page-header, .searchbox{
		display: table-cell;
		vertical-align: middle
	}
	.searchbox {
		width: 300px;
		.form-control{
			background-color: transparent;
			.transition(background-color .5s, border-color .5s);
			&:focus{
				background-color: #fff;
				.transition(background-color .5s, border-color .5s);
			}
		}
	}
}
@media (min-width: 992px){
	.searchbox{
		margin-right: -25px;
		.custom-search-form{
			margin-right: 0;
		}
	}
	#page-title{
		padding: 10px 25px 0;
	}
}










/* CLEAN ZONE (LOGIN, REGISTER, AND ERROR PAGES CONTAINER) */
/*======================================*/
.cls-container{
	background-color: @cls-bg;
	color: @cls-color;
	text-align: center;
	a, a:hover, a:focus{
		color: @cls-color;
	}
	.text-light a{
		&, &:hover, &:focus{
			color: #fff;
		}

	}
}

/* Image background */
.bg-img{
	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: .55;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

/* Header */
.cls-header{
	position: relative;
	background-color: rgba(0, 0, 0, 0.1);
	&:after {
		content: "";
		display: block;
		border-top:10px solid rgba(0, 0, 0, 0.1);
		border-left:10px solid transparent;
		border-right:10px solid transparent;
		width: 0;
		height: 0;
		position: absolute;
		left: 50%;
		margin-bottom: -10px;
		margin-left: -10px;
		bottom: 0
	}
	&.cls-header-lg .cls-brand .brand-icon{
		width: 60px;
		height: 60px
	}
	#container.cls-container &{
		.cls-header.cls-header-lg .cls-brand .brand-title{
			font-size: 27px;
		}
	}
}

/* Brand */
.cls-brand{
	display: inline-block;
	padding: 20px 0;
	.brand-icon{
		padding: 0;
	}
	a{
		&:hover{
			.brand-icon,
			.brand-title{
				opacity: 1;
				color: #fff;
				transition: all .5s
			}
		}
		.brand-icon,
		.brand-title{
			opacity: .7;
			transition: all .5s
		}
	}
	.cls-header-lg &{
		line-height: 60px;
	}
	.cls-header-sm &{
		line-height: 1.42857
	}
	#container.cls-container &{
		.brand-icon,
		.brand-title{
			background-color: transparent;
			color: inherit;
			float: none;
			display: inline-block;
			line-height: 22px;
			font-size: 20px;
			font-weight: @font-semibold;
			padding: 5px;
			vertical-align: middle;
			width: auto
		}
	}

}
/* Content */
.cls-content{
	padding: 50px 15px 15px;
	padding-top: 10vh;
	position: relative;
	.error-code{
		font-size: 100px;
		font-weight: 300
	}
	.error-search{
		max-width: 512px;
		margin: 0 auto
	}
	.cls-content-sm,
	.cls-content-lg{
		color: #515151;
		width: 70%;
		min-width: 270px;
		margin: 0 auto;
		position: relative
	}
}

@media (min-width: 768px){
	.cls-content .cls-content-sm{
		width: 350px;
	}
	.cls-content .cls-content-lg{
		width: 700px;
	}
}



/* Scrollbar */
/*======================================*/
.nano {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	> .nano-content {
		position: absolute;
		overflow: scroll;
		overflow-x: hidden;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		&::-webkit-scrollbar {
			display: none;
		}
		&:focus {
			outline: 0 !important
		}
	}
	> .nano-pane {
		background-color: rgba(0, 0, 0, .1);
		position: absolute;
		width: 5px;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0;
		.transition(all .7s);
		>.nano-slider {
			background-color: rgba(0, 116, 255, 0.5);
			position: relative;
			margin: 0;
			border-radius: 0
		}
		.nano-hide&{
			display: none!important
		}
	}
}
.has-scrollbar>.nano-content::-webkit-scrollbar {
	display: block;
}


.nano:hover > .nano-pane,
.nano-pane.active,
.nano-pane.flashed {
	opacity: .99
}


.pace{
	.pace-progress{
		background-color: @state-active-bg;
	}
	.pace-progress-inner {
		box-shadow:0 0 10px @state-active-bg,0 0 5px @state-active-bg;
	}
	.pace-activity {
		background-color: @state-active-bg;
	}
}

.nano > .nano-pane > .nano-slider{
	&:extend(.pace .pace-progress);
}



