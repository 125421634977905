
@import "fonts.css";

a:hover {
    cursor: pointer;
}

@import "../node_modules/bootstrap/less/bootstrap.less";
@import "nifty/nifty.less";
@import "../node_modules/eonasdan-bootstrap-datetimepicker/src/less/_bootstrap-datetimepicker";
@import "../node_modules/datatables.net-bs/css/dataTables.bootstrap.css";
@import "font-awesome/font-awesome.less";
@import "../node_modules/easymde/dist/easymde.min.css";
@import "../node_modules/bootstrap-sortable/Contents/bootstrap-sortable.css";
@import"../node_modules/survey-core/defaultV2.css";
@import "../node_modules/survey-creator-core/survey-creator-core.css";
@import "../node_modules/select2/dist/css/select2.css";
@import "../node_modules/metismenu/dist/metisMenu.css";

@import "compositions/_repel.less";

.page-header .label {
    font-size: 75%;
}

blockquote.small {
    padding: 5px 10px;
}

.control-label {
    font-weight: bold;
}

.label-subject {
    background-color: @purple-bg;
}

.label-partner {
    background-color: @mint-bg;
}

.label-proxy {
    background-color: @pink-bg;
}

.badge-subject {
    background-color: @purple-bg;
}

.badge-partner {
    background-color: @mint-bg;
}

.badge-proxy {
    background-color: @pink-bg;
}

.form-control-sm {
    max-width: 265px;
}

.btn-primary-spacing {
    margin-left: 5px;
    margin-bottom: 5px !important;
}

.grid-style {
    border: 1px dashed gray;
    padding: 0.5rem 1rem;
    margin-bottom: .5rem;
    cursor: move;
}

.flex-col-st {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dropdown.timepoint-menu {
    width: 160px;
    float: right;
}

.grid-style-container {
    border: 1px dashed gray;
    padding: 0.5rem 1rem;
    text-decoration: none;
}

.form-radio.study-rad {
    margin: auto auto 1.5em 1em;
}

.test-banner {
    font-size: 18px;
}

.basket {
    opacity: 1;
    transition: opacity 0.4s ease-out;
    -ms-transition: opacity 0.4s ease-out;
    -moz-transition: opacity 0.4s ease-out;
    -webkit-transition: opacity 0.4s ease-out;
}

.searchbox {
    display: table-cell;
    vertical-align: middle;
}

.o-hide {
    opacity: 0;
    transition: opacity 0.4s ease-in;
    -ms-transition: opacity 0.4s ease-in;
    -moz-transition: opacity 0.4s ease-in;
    -webkit-transition: opacity 0.4s ease-in;
}

.navbar-form {
    padding-top: 0;
    padding-bottom: 0;
}

.bottom-pad {
    padding-bottom: 10px;
}

.validation-summary-valid {
    display: none;
}

.hide-me {
    display: none;
}

.indent-2em {
    text-indent: 2em;
}

.upload- {
    &anchor {
        position: relative;
    }

    &overlay {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        background-color: rgba(200, 200, 200, 0.75);
        padding: 1em;

        .bord-all {
            width: 100%;
            height: 100%;
            border-style: dashed;
            border-width: thick;
        }
    }

    &file {
        visibility: hidden;
    }
}

.checklist-10em {
    max-height: 10em;
    overflow-y: auto;
    border: 1px solid #eee;
    div {
        padding: .3em;
    }
}

.move-vert-btns {
    display: inline-flex;
    vertical-align: middle;
    flex-direction:column;
    justify-content: center;
    div {
        cursor:pointer;
    }
}


.field-validation-error {
    .text-danger;
}

.sub-table{
    .table;
}
.scroll-list {
    max-height: 10em;
    border: 1px solid #eee;
    overflow-y: scroll;
}

.col {
    &-container {
        display: none;
        &.active {
            display: block;
        }
    }
    &-name {
        width: 100%;
        text-align: center;
        padding: .3em;
        margin: auto;
    }
    &-desc {

    }
}

.dl-horizontal {
    max-height: 150px;
    overflow-y: auto;
}

.scroll-divs {
    height:100%;
    .scroll-max {
        max-height: 100%;
        overflow-y: auto;
	}
}

.coding-form {
    display: none;
    &.active {
        display: block;
    }
}

.toggle-ds-table {
    display: none;
    &.visible {
        display: block;
    }
}

.toggle-display {
    display: none;
    &.visible {
        display: block;
    }
}

#img-disp-container {
    max-height:20vh;
    max-width: 100%;
    img {
        max-height: inherit;
        max-width: inherit;
        &:hover {
            outline: auto @brand-primary;
        }
    }
}

li.dropdown-menu-btn {
    box-sizing: border-box;
    padding: 12px;
}

.dropdown-menu-btn + .dropdown-menu-btn {
    padding-top: 0;
}

.dropdown-menu .btn {
    width: 100%;
    display: block;
    text-align: left;
}

.dropdown-menu a.btn {
    &.btn-default {
        .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
    }

    &.btn-primary {
        .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
    }
    // Success appears as green
    &.btn-success {
        .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
    }
    // Info appears as blue-green
    &.btn-info {
        .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
    }
    // Warning appears as orange
    &.btn-warning {
        .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
    }
    // Danger and error appear as red
    &.btn-danger {
        .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
    }
}

fieldset > .form-group {
    padding-left: 10px;
}

.display-none {
    display: none;
}
#add-users-form .tt-input {
    min-width: 256px;
}

#add-users-form .tt-hint {
    width: 100%;
}

a[aria-expanded='true'] > .expand-caret {
    transform: rotate(90deg);
}

.btn-no-pad {
    padding: 0;
    border: 0;
}

.no-box-shadow {
    box-shadow: none !important;
}

.modal-fullscreen {
    width: 100% !important;
    height: 100% !important;
    margin: 0;
    top: 0;
    left: 0;
}

.tab-control {
    float: right;
}

.tab-control > form {
    display: inline-block;
}

.select2-container {
    width: 100% !important;
}

.select2-container--open .select2-dropdown--below,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
    border-radius: @border-radius-base;
    border: 1px solid @border-color-base;
}

.select2-container--default.select2-container--focus .select2-selection--single,
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: @state-active-bg;
}

.page-header .modal {
    font-size: 1.4rem;
    color: black;
}

td.limit-text {
    max-width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.loading {
    background-image: url("bars.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 3ch;
    min-width: 3ch;
    display: inline-block;
}

.loading::after {
    content: "\00a0";
}

.modal-dialog {
    font-size: @font-size-base;
    line-height: @line-height-base;
}