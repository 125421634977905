body, #content-container{
	background-color: @body-bg;
	color:@body-color;
}

#container.boxed-layout{
	background-color: multiply(@body-bg, #e5e5e5);
}


.page-header {
	color: lighten(@body-color, 17%);
}

a{
	color:@link-color;
	&:hover,
	&:focus{
		color: @link-hover-color;
	}
}

/* SEARCHBOX */
/*======================================*/
.searchbox {
	> .input-group input:focus{
		border-color: @state-active-bg;
	}
}


/* Scrollbar */
/*======================================*/
.pace{
	.pace-progress{
		background-color: @state-active-bg;
	}
	.pace-progress-inner {
		box-shadow:0 0 10px @state-active-bg,0 0 5px @state-active-bg;
	}
	.pace-activity {
		background-color: @state-active-bg;
	}
}

.nano > .nano-pane > .nano-slider{
	&:extend(.pace .pace-progress);
}


/* NAVBAR
/*====================================*/
#navbar{
	background-color: @body-bg;
}
& when (lightness(@mainnav-bg) < 70%) {
	#navbar-container{
		box-shadow: 0 2px 0px -1px rgba(0,0,0,.05);
	}
}

& when (lightness(@mainnav-bg) >= 70%) and (lightness(@navbar-bg) >= 70%)  {
	#navbar-container{
		box-shadow: 0 2px 0px -1px darken(@body-bg, 7.5%);
	}
}
.navbar-header{
	background-color: @brand-bg-overlay
}

.navbar-content{
	background-color: @navbar-bg;
	box-shadow: inset -4px 0 0 0 @state-active-bg
}


/* BRAND LOGO & TEXT
/*====================================*/
.navbar-brand{
	background-color: @brand-bg;
	color: @mainnav-hover-color;
	&:hover, &:focus{
		color: @mainnav-hover-color;
	}
}

/* NAVIGATION */
/*======================================*/
& when (lightness(@mainnav-bg) >= 70%) {
	#mainnav-container{
		box-shadow: 0 0 0 1px darken(@body-bg, 7.5%);
	}
}

/* NAVBAR ICON & BUTTON */
/*======================================*/
.navbar-top-links{
	> li{
		> a {
			color: @navbar-color;
			&:focus{
				background-color: transparent;
			}
			&:hover{
				background-color: darken(@navbar-bg, 5%);
				color: darken(@navbar-color,15%);
			}
		}
	}
	> .open{
		> a, > a:focus{
			background-color: darken(@navbar-bg, 5%);
			color:@navbar-color;
		}
	}
	.tgl-menu-btn{
		> a, > a:hover, > a:focus{
			color: @brand-color
		}
	}
}

/* NAVBAR RESPONSIVE */
/*======================================*/
@media (min-width: 768px){
	#navbar{
		background-color: transparent
	}

	.navbar-header{
		background-color: transparent;
		&:before{
			background-color: @brand-bg-overlay
		}
	}
	#navbar-container{
		background-color: @navbar-bg
	}
	#container.mainnav-in .brand-title,
	#container.mainnav-sm .brand-title,
	#container.mainnav-lg .brand-title{
		color: @brand-color;
	}
	.navbar-top-links{
		.tgl-menu-btn{
			position: static;
			> a, > a:hover{
				color: @navbar-color
			}
			> a:focus{
				background-color: transparent;
				color:@navbar-color
			}
		}
	}
}



/* NAVIGATION */
/*======================================*/
#mainnav{
	background-color: @mainnav-bg;
	.list-header{
		color:darken(@mainnav-color,15%)
	}
}




/* NAVIGATION MENU */
/*======================================*/


#mainnav-menu{
	a {
		color: @mainnav-color;
	}
	>li{
		>a{
			&:hover, &:active{
				color: @mainnav-hover-color
			}
			&.hover{
				color: @mainnav-hover-color;
				background-color: lighten(@mainnav-bg,3%);
				box-shadow:inset 4px 0 0 0 @mainnav-active-state
			}
		}
	}
	> .active-link{
		>a, >a:hover{
			color: @mainnav-hover-color;
			box-shadow:inset 4px 0 0 0 @mainnav-active-state;
		}
	}
	> .mm-active-sub {
		> a, >a:hover{
			color: @mainnav-hover-color;
		}
	}
	> .mm-active {
		background-color: darken(@mainnav-bg, 3%);
		.mm-active{
			background-color: darken(@mainnav-bg, 5%);
		}
	}
	ul{
		a{
			&:hover{
				color: @mainnav-hover-color;
			}
		}
		ul{
			a{
				&:hover{
					color: @mainnav-hover-color;
				}
			}
		}
		.active-link{
			a{
				color: @mainnav-hover-color;
				box-shadow:inset 4px 0 0 0 @mainnav-active-state;
			}
		}
	}
}




.menu-popover{
	.sub-menu{
		ul{
			&:extend(#mainnav-menu ul);
			a{
				&:extend(#mainnav-menu ul a);
				&:hover{
					&:extend(#mainnav-menu ul a:hover);
				}
			}
			ul{
				&:extend(#mainnav-menu ul ul);
				a:hover{
					&:extend(#mainnav-menu ul ul a:hover);
				}
			}
			.active-link{
				a{
					&:extend(#mainnav-menu ul .active-link a);
				}
			}
		}
	}
}


& when (@mainnav-collapse = true){
	#container.mainnav-sm{
		#mainnav-menu{
			a:hover{
				box-shadow:inset 4px 0 0 0 @mainnav-active-state;
			}
			>.mm-active{
				.active-link, .mm-active-sub{
					> a, a:hover{
						background-color: @mainnav-active-state;
					}
				}
			}
		}
	}
}



/* NAVIGATION - SHORTCUT BUTTONS */
/*======================================*/
#mainnav-shortcut{
	.shortcut-grid{
		color: @mainnav-hover-color;
	}
}




 /* NAVIGATION - WIDGET */
/*======================================*/
.mainnav-widget{
	background-color: rgba(0,0,0,.05);
	color:@mainnav-color;
}





& when (@mainnav-collapse = true){
	@media (min-width: 768px){
		#container.mainnav-sm{
			.shortcut-grid:hover{
				box-shadow:inset 4px 0 0 0 @mainnav-active-state;
			}
			.shortcut-grid:hover,
			.popover.mainnav-shortcut{
				background-color: lighten(@mainnav-bg, 3%);
				color: @mainnav-hover-color;
			}
			#mainnav-menu{
				.active-link, .mm-active-sub{
					> a{
						color: @state-active-color;
					}
				}
			}
			#mainnav{
				.mainnav-widget{
					>.show-small{
						a{
							color:@mainnav-color;
							&:hover{
								color:@mainnav-hover-color;
								box-shadow:inset 4px 0 0 0 @mainnav-active-state;
							}
						}
					}
					.hover{
						color: @mainnav-hover-color;
						background-color: lighten(@mainnav-bg, 3%);
					}
				}
			}

		}

		/* NAVIGATION - POPOVER  */
		/*======================================*/
		.menu-popover{
			.sub-menu{
				ul{
					background-color: darken(@mainnav-bg,3%);
					ul{
						background-color: darken(@mainnav-bg,5%);
					}
					li{
						>a{
							color: @mainnav-hover-color;
						}
					}
				}
			}
			.single-content{
				color: @mainnav-hover-color;
				background-color: lighten(@mainnav-bg, 3%);
			}
			&.popover{
				background-color: darken(@mainnav-bg,2%);
				color:@mainnav-hover-color;
			}
			>.popover-title{
				background-color: lighten(@mainnav-bg, 3%);
				color:@mainnav-hover-color;
			}
			.mainnav-widget-content{
				background-color: lighten(@mainnav-bg, 3%);
			}
		}
	}
	.mainnav-sm .popover.mainnav-shortcut{
		background-color: darken(@mainnav-bg,2%);
		color: @mainnav-hover-color;
	}
}




/* NAVIGATION - OFFCANVAS */
/*======================================*/
@media (min-width: 768px){
	#container.mainnav-in.easeInBack #mainnav-container{
		box-shadow: -20px 0 0 0 @mainnav-bg;
	}
	#container.mainnav{
		&-in{
			&.easeOutBack #mainnav-container{
				&:extend(#container.mainnav-in.easeInBack #mainnav-container);
			}
			&.easeInOutBack #mainnav-container{
				&:extend(#container.mainnav-in.easeInBack #mainnav-container);
			}
			&.jumping #mainnav-container{
				&:extend(#container.mainnav-in.easeInBack #mainnav-container);
			}
		}
		&-out{
			.brand-title{
				background-color: transparent;
				color: @brand-color-overlay;
			}
			&.easeInBack #mainnav-container{
				&:extend(#container.mainnav-in.easeInBack #mainnav-container);
			}
			&.easeOutBack #mainnav-container{
				&:extend(#container.mainnav-in.easeInBack #mainnav-container);
			}
			&.easeInOutBack #mainnav-container{
				&:extend(#container.mainnav-in.easeInBack #mainnav-container);
			}
			&.jumping #mainnav-container{
				&:extend(#container.mainnav-in.easeInBack #mainnav-container);
			}
		}
	}
}


/* ASIDE */
/* ===================================== */
#container {
	#aside-container{
		background-color: @aside-bright-bg;
	}
	#aside {
		.list-link li a:not(.btn){
			color: darken(@aside-dark-color,18%);
			&:hover{
				color: @aside-dark-color;
			}
		}
	}
}

#aside{
	background-color: @aside-dark-bg;
	color: @aside-dark-color;
	a:not(.btn):not(.btn-link){
		color: inherit;
	}
	.bord-all,
	.bord-top,
	.bord-btm,
	.bord-left,
	.bord-rgt,
	.bord-hor,
	.bord-ver,
	.list-divider{
		border-color:lighten(@aside-dark-bg,3%);
	}
	.btn-link{
		color: @aside-dark-color;
	}
	.text-muted{
		color: lighten(@aside-dark-bg,35%);
	}
}


& when (@aside-tabs){

	/* Aside with tabs */
	/* ===================================== */
	#aside{
		#aside-container &{
			.nav-tabs{
				li:not(active){
					a{
						background-color: darken(@aside-dark-bg,3%);
					}
				}
			}
		}
		#container.aside-bright &{
			.nav-tabs {
				li:not(.active) a{
					background-color: darken(@aside-bright-bg,5%)
				}
			}
		}
	}
}


@media (min-width: 768px){
	#aside{
		background-color: @aside-dark-bg;
	}
	#container{
		#aside-container{
			background-color: @aside-bright-bg;
		}
	}
}


/* ASIDE : BRIGHT COLOR THEMES */
/* ===================================== */
#container{
	&.aside-bright{
		#aside{
			background-color: @aside-bright-bg;
			border-color: darken(@aside-bright-bg, 5%);
			color: @aside-bright-color;
			& when (lightness(@mainnav-bg) >= 70%){
				border: 1px solid darken(@body-bg, 7.5%);
			}
			& when (lightness(@body-bg) >= 95%){
				border: 1px solid darken(@body-bg, 7.5%);
			}
			.text-light{
				color : @body-color;
			}
			.bord-all,
			.bord-top,
			.bord-btm,
			.bord-lft,
			.bord-rgt,
			.bord-hor,
			.bord-ver,
			.list-divider{
				border-color: darken(@aside-bright-bg, 3%);
			}
			.text-muted{
				color : lighten(@body-color,30%);
			}
			.progress{
				background-color: darken(@aside-bright-bg, 10%);
			}
			.list-link li a:not(.btn){
				color: lighten(@aside-bright-color,5%);
				&:hover{
					color: @aside-bright-color
				}
			}
			a:not(.btn){
				color: @aside-bright-color;
				&:hover, &:focus{
					color: darken(@aside-bright-color,10%);
				}
			}
			.btn-link{
				color: @aside-bright-color;
			}
		}

	}

}



/* FOOTER */
/*======================================*/
#container.footer-fixed{
	#footer{
		background-color: @footer-bg;
		color:@footer-color;
	}
}



/* BORDER */
/*======================================*/
.bord-no{border:0 !important}
.bord-all{border:1px solid @border-color-base}
.bord-top{border-top:1px solid @border-color-base}
.bord-btm{border-bottom: 1px solid @border-color-base}
.bord-lft{border-left: 1px solid @border-color-base}
.bord-rgt{border-right:1px solid @border-color-base}
.bord-ver{
	border-top: 1px solid @border-color-base;
	border-bottom: 1px solid @border-color-base
}
.bord-hor{
	border-right: 1px solid @border-color-base;
	border-left: 1px solid @border-color-base
}




/* TIMELINE */
/*======================================*/
& when(@timeline = true) {
	.timeline {
		background-color: @timeline-bg;
		color: @timeline-color;
		&:before, &:after{
			background-color: lighten(@timeline-border-color, 12%);
		}
	}
	.timeline-time{
		background-color: @timeline-bg;
		color: lighten(@timeline-color, 20%);
	}
	.timeline-icon {
		box-shadow: 0 0 0 7px @body-bg;
	 	&:empty {
			background-color: @timeline-border-color;
		}
	}
	.timeline-label {
		background-color: @light-bg;
		border:1px solid @border-color-base;
		&:before{
			border-right: 10px solid darken(@border-color-base,1%);
		}
		&:after{
			border-right: 9px solid @light-bg;
		}
	}
	.panel {
		.timeline-label{
			background-color: darken(@light-bg, 3%);
			border:1px solid darken(@border-color-base, 2.5%);
			&:before{
				border-right-color: darken(@border-color-base, 2.5%);
			}
			&:after{
				border-right-color: darken(@light-bg, 3%);
			}
		}
	}

	@media (min-width: 768px){
		.two-column.timeline{
			.timeline-entry:nth-child(even){
				.timeline-label{
					&:before{
						border-left: 10px solid darken(@border-color-base,1%);
					}
					&:after{
						border-left: 9px solid @light-bg;
					}
				}
			}
		}
	}
}





/* FORM WIZARD */
/*======================================*/
& when (@form-wizard = true){
	// Black & white icons
	.wz-icon-bw li {
		&.active ~ li > a .icon-wrap{
			color: @body-color;
			background-color: rgba(0,0,0,.17);
		}
		&:not(.active) > a p {
			color: @body-color !important;
		}
	}
}





 /* FORM CONTROL  */
/*======================================*/
& when (@form-control = true) {
	.form-control{
		&:focus{
			border-color:@state-active-bg;
		}
	}
}



/* LIST GROUP */
/*======================================*/
& when (@list-group = true) {
	.list-group-item{
		&.active{
			&, &:hover, &:focus{
				background-color: @state-active-bg;
				border-color: @state-active-bg;
				color: @state-active-color;
				.list-group-item-text{
					color: @state-active-color;
				}
			}
		}
	}
}



 /* DROPDOWN */
/*======================================*/
& when (@dropdown = true) {
	.dropdown-menu{
		>li{
			>a:not(.btn){
				&:hover{
					background-color: @state-active-bg;
					color: @state-active-color
				}
			}
		}
	}
}



/*======================================*/
/* PAGER */
/*======================================*/
& when (@pager = true) {
	.pager{
		li{
			>a{
				&:hover,&:focus{
					background-color: @light-bg;
					border-color: @state-active-bg;
					color: @state-active-bg;
					box-shadow: inset 0 0 1px @state-active-bg;
					z-index: 2;
					.transition(border-color, .3s)
				}
			}
		}
	}
}




/*======================================*/
/* PAGINATION */
/*======================================*/
& when (@pagination = true){
	.pagination{
		>li{
			a {
				&:hover,
				&:focus{
					border-color: @state-active-bg;
					color: @state-active-bg;
					box-shadow: inset 0 0 1px @state-active-bg;
				}
			}
		}
		>.active{
			>a, >span, >a:hover, >span:hover, >a:focus, >span:focus{
				background-color:@state-active-bg;
				border-color: @state-active-bg;
			}
		}
	}
}




/* TAB */
/*======================================*/
& when (@tabs = true){
	.tab{
		&-base{
			.tab-content{
				.tab-footer{
					background-color: lighten(@body-bg,3%);
					color: @body-color;
					border-color: lighten(@body-bg,1%);
				}
			}
			.nav-tabs{
				>li:not(.active){
					>a{
						&:hover{
							border-color: @state-active-bg;
						}
					}
				}
			}
		}

	}
	.tab-stacked{
		&-left{
			&.tab-base{
				.nav-tabs{
					>li:not(.active){
						a{
							&:hover{
								border-color: @state-active-bg;
								border-right-color: transparent;
							}
						}
					}
				}
			}
		}
		&-right{
			&.tab-base{
				.nav-tabs{
					>li:not(.active){
						a{
							&:hover{
								border-color: @state-active-bg;
								border-left-color: transparent;
							}
						}
					}
				}
			}
		}
	}
}



/* BUTTONS */
/*======================================*/
.btn-trans{
	color:lighten(@body-color, 20%);
}





/* PANELS */
/*======================================*/
.panel{
	& when (lightness(@body-bg) >= 95%) {
		&:not(.panel-colorful){
			border: 1px solid darken(@body-bg, 5.5%);
		}
	}
}
.panel-footer{
	background-color: lighten(@body-bg,3%);
	color: @body-color;
	border-color: lighten(@body-bg,1%);
	position: relative
}





